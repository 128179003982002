<script>
import _ from 'lodash'
import zodiacCalc from '@/../plugit/@astralito/books/calc-zodiac-sign.js'
import imgAlertCircle from '../assets/alert-circle.svg'

export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			tabIndex: -1,
			imgAlertCircle,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		tabs() {
			let data = this.e.customization.data
			let { $lang } = this
			return [
				{
					title: $lang('Nombre'),
					get value() {
						return data.name
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name && data.gender && data.birthdate && data.lang
					},
				},
				{
					title: $lang('Dedicatoria'),
					get value() {
						return data.inscription
					},
					incompleteValue: $lang('Escribe una dedicatoria'),
					get complete() {
						return data.inscription
					},
				},
			]
		},
		dataChecker() {
			let data = this.e.customization.data
			let checker = _.pick(data, ['name', 'gender', 'birthdate', 'lang', 'inscription'])
			return JSON.stringify(checker)
		},
		zodiacSrc() {
			let sign = zodiacCalc(this.e.customization.data.birthdate)
			return this.e.config[`${sign}ZodiacImg`]
		},
	},
}
</script>

<template>
	<BookEditor-Tabs :tabs="tabs" :data-checker="dataChecker" v-model="tabIndex">
		<template #item1>
			<div class="pr-md-6 w100">
				<BookEditorInput-TextField
					model-key="name"
					:label="$lang('Este libro personalizado es para')"
					:placeholder="$lang('Nombre')"
				/>
				<BookEditorInput-GenderCharacters />
			</div>
			<div class="pr-md-6 pt-4 pt-md-0 w100">
				<BookEditorInput-DatePicker model-key="birthdate" :label="$lang('Su fecha de nacimiento')" />
				<v-menu open-on-hover top left offset-y offset-overflow v-if="zodiacSrc">
					<template v-slot:activator="{ on, attrs }">
						<div class="d-flex justify-center zodiac">
							<Media :src="zodiacSrc" max-width="110" eager />
							<div class="zodiac__info-icon" v-on="on" v-bind="attrs">
								<Media :src="imgAlertCircle" width="20" eager />
							</div>
						</div>
					</template>
					<div
						class="blue lighten-4 elevation-6 pa-4 d-flex align-center flex-column flex-sm-row justify-center zodiac-info-alert"
					>
						<div class="pb-4 pb-sm-0 pr-sm-4" style="width: 25%; opacity: 0.7">
							<Media :src="imgAlertCircle" width="100%" />
						</div>
						<div class="font-1" style="width: 100%">
							<span class="font-weight-bold darkpink--text">
								{{
									'Es muy importante que revises el signo zodiacal asignado por el sistema a tu libro,'
										| lang
								}}
							</span>
							<span class="blue--text">
								{{
									'y si crees que debería ser otro, selecciona en el calendario un día antes o uno después para modificarlo (Aclaración: la fecha de nacimiento no aparece en el libro).'
										| lang
								}}
							</span>
						</div>
					</div>
				</v-menu>
			</div>
			<div class="pt-4 pt-md-0 w100">
				<BookEditorInput-LangSelect />
			</div>
		</template>
		<template #item2>
			<BookEditorTab-Inscription />
		</template>
	</BookEditor-Tabs>
</template>

<style lang="scss" scoped>
.zodiac {
	position: relative;
}
.zodiac__info-icon {
	position: absolute;
	cursor: pointer;
	transition: all 0.3s;
	left: 50%;
	transform: translateX(46px);
	bottom: 10px;
	&:hover {
		transform-origin: center;
		transform: translateX(46px) scale(1.2);
	}
}
.zodiac-info-alert {
	max-width: 360px;
}
</style>
