<script>
export default {
	lang: 'books',
	props: ['modelKey', 'label', 'maxLen', 'placeholder'],
	inject: ['editor'],
	computed: {
		e() {
			return this.editor()
		},
		cMaxLen() {
			return this.maxLen || this.e.config[`${this.modelKey}MaxLen`] || null
		},
	},
	mounted() {
		this.$assignDefaults(this.e.customization.data, { [this.modelKey]: null })
	},
}
</script>

<template>
	<div class="input-container">
		<div class="primary--text">{{ label }}</div>
		<TextField
			v-model="e.customization.data[modelKey]"
			:validator-key="`customization.data.${modelKey}`"
			:placeholder="placeholder"
			solo
			elevation="1"
			:counter="cMaxLen"
			:maxlength="cMaxLen"
			class="darkblue--text font-weight-bold font-2 input-text-center"
			append-icon="mdi-pencil-box-outline"
			v-bind="$attrs"
		/>
	</div>
</template>

<style scoped>
.input-container {
	height: 100px;
}
</style>
