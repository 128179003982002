<script>
export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			count: 0,
			itv: null,
			finished: false,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		cStyle() {
			if (this.e.pageSize && this.e.firstPreviewReady) {
				return { height: `${this.e.pageSize.h}px` }
			} else {
				return {}
			}
		},
	},
	created() {
		this.itv = setInterval(() => {
			this.count += 1
			if (this.count == 100) {
				clearInterval(this.itv)
				this.finished = true
			}
		}, 10000 / 100)
	},
	beforeDestroy() {
		clearInterval(this.itv)
	},
}
</script>

<template>
	<div class="d-flex align-center justify-center flex-column w100" style="min-height: 450px" :style="cStyle">
		<div class="pb-4 font-4 font-weight-bold orange--text" style="opacity: 0.7">
			<small v-if="finished">{{ 'Solo un momento más...' | lang }}</small>
			<span v-else>{{ count }}%</span>
		</div>
		<div class="pb-8 font-3 font-weight-bold darkblue--text text-center">
			{{ '¡Ya estás más cerca de tu libro!' | lang }}
		</div>
		<div class="balls-loader" style="height: 100px">
			<div class="ball-1"></div>
			<div class="ball-2"></div>
			<div class="ball-3"></div>
			<div class="ball-4"></div>
		</div>
	</div>
</template>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

@keyframes ball {
	0% {
		/*left: 0%;*/
		transform: translateX(0);
	}
	100% {
		/*left: 100%;*/
		transform: translateX(105vw);
	}
}

.balls-loader {
	position: relative;
	width: 100%;
	div {
		width: 35px;
		height: 35px;
		position: absolute;
		border-radius: 50%;
		left: -35px;
	}

	.ball-1 {
		background-color: var(--v-green-base);
		animation: ball 2s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
	}
	.ball-2 {
		background-color: var(--v-darkpink-base);
		animation: ball 2s 150ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
	}
	.ball-3 {
		background-color: var(--v-blue-base);
		animation: ball 2s 300ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
	}
	.ball-4 {
		background-color: var(--v-orange-base);
		animation: ball 2s 450ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
	}
	@media #{map-get($display-breakpoints, 'xs-only')} {
		div {
			width: 17px;
			height: 17px;
			left: -17px;
		}
		.ball-1 {
			animation: ball 2s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
		}
		.ball-2 {
			animation: ball 2s 300ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
		}
		.ball-3 {
			animation: ball 2s 600ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
		}
		.ball-4 {
			animation: ball 2s 900ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
		}
	}
}
</style>
