<script>
import { getSrcFromFile, getImageElementFromSrc } from '@/__shared/utils/lib_imageUtils'
import grayscale from './grayscale'
import inputMixin from '@/site/site/common/mixin_input'
export default {
	lang: 'book',
	name: 'InputFile',
	mixins: [inputMixin],
	props: ['value', 'options', 'initialSrc'],
	model: {
		prop: 'value',
		event: 'input',
	},
	data() {
		return {
			file: null,
			src: null,
			cropperSrc: null,
			cropperDialog: false,
			srcChanged: false,
			loading: false,
			canRotate: false,
			errorMessage: '',
			snackbarError: false,
		}
	},
	watch: {
		async file(file) {
			if (!file) return
			this.loading = true
			let src = await getSrcFromFile(file)
			const { isValid, canRotate } = await this.validateSize(src)
			const isFirstImage = !this.cropperSrc
			if (isValid) {
				this.cropperSrc = await grayscale(src)
				this.cropperDialog = true
				this.canRotate = canRotate
			} else if (!isValid && isFirstImage) {
				this.file = null
			}
			this.loading = false
		},
	},
	computed: {
		displaySrc() {
			return this.src || this.initialSrc
		},
	},
	methods: {
		onChange({ target: { files } }) {
			if (files.length) {
				this.file = files[0]
				this.$refs.input.value = null
			}
		},
		onDrop(evt) {
			evt.preventDefault()
			const files = evt.dataTransfer.files
			if (files.length) this.file = files[0]
		},
		async validateSize(src) {
			const { width, height } = await getImageElementFromSrc(src)
			const { minWidth, minHeight } = this.options
			const validHeight = height >= minHeight
			const validWidth = width >= minWidth
			if (!validHeight || !validWidth) {
				this.showErrorMessage({ minWidth, minHeight })
				return { isValid: false }
			}
			const canRotate = height >= minWidth
			return { isValid: true, canRotate }
		},
		async onCropperDone({ src, file }) {
			this.src = src
			this.$emit('input', file)
		},
		showErrorMessage({ minWidth, minHeight }) {
			this.errorMessage = this.$lang(
				`¡Queremos que tu libro se vea perfecto! Para esto, necesitas tener una foto de una altura de al menos {minHeight}px y una anchura de {minWidth}px.`,
				{
					minHeight,
					minWidth,
				}
			)
			this.snackbarError = true
		},
	},
}
</script>

<template>
	<div class="w100">
		<v-snackbar
			:timeout="5000"
			v-model="snackbarError"
			color="error"
			rounded="pill"
			absolute
			top
			id="snackbar"
		>
			<span class="font-1">{{ errorMessage }}</span>
			<template v-slot:action="{ attrs }">
				<v-btn icon dark v-bind="attrs" @click="snackbarError = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<input type="file" class="d-none" ref="input" @change="onChange" />
		<v-hover v-slot="{ hover }">
			<v-card
				@drop="onDrop"
				@dragover="$event.preventDefault()"
				color="transparent"
				:style="{
					border: '1px dashed var(--v-darkblue-base) !important',
					overflow: 'hidden',
					margin: 'auto',
				}"
				height="250"
				width="500"
				max-width="90%"
				class="d-flex justify-center align-center"
				elevation="0"
			>
				<v-overlay
					absolute
					:value="hover"
					v-if="displaySrc"
					@click.prevent="$refs.input.click()"
					style="cursor: pointer"
				>
					<div class="w100 h100 d-flex flex-column justify-center align-center primary--text">
						<v-icon color="primary">mdi-upload</v-icon>
						<span>{{ 'Arrastra la foto aqui' | lang }}</span>
					</div>
				</v-overlay>
				<div class="text-center w100 h100">
					<div v-if="displaySrc" class="w100 h100">
						<Media :src="displaySrc" width="100%" height="100%" contain />
					</div>
					<div v-else class="w100 h100 d-flex flex-column justify-center align-center primary--text">
						<v-icon color="white">mdi-upload</v-icon>
						<div class="pb-2">{{ 'Arrastra la foto aquí o...' | lang }}</div>
						<Button color="darkblue" @click.prevent="$refs.input.click()" :loading="loading">
							{{ $lang('Cargar foto') }}
						</Button>
					</div>
				</div>
			</v-card>
		</v-hover>
		<div class="d-flex justify-center align-center mt-4" v-if="displaySrc">
			<Button color="darkblue" @click="$refs.input.click()" class="mr-4" :loading="loading">
				{{ $lang('Cambiar foto') }}
			</Button>
			<Button @click="cropperDialog = true" color="darkblue" v-if="cropperSrc && src">
				{{ $lang('Ajustar') }}
				<v-icon right>mdi-resize</v-icon>
			</Button>
		</div>
		<div class="error--text text-caption px-1" v-for="err of validationErrors" :key="err">
			{{ err }}
		</div>
		<BookEditorInput-Image-CropperDialog
			v-model="cropperDialog"
			:options="{
				minWidth: options.minWidth,
				minHeight: options.minHeight,
				canRotate,
			}"
			:filename="file && file.name"
			:src="cropperSrc"
			@done="onCropperDone"
		/>
	</div>
</template>
