<script>
export default {
	lang: 'books',
	inject: ['editor'],
	props: {
		fullWidth: Boolean,
		useSlider: Boolean,
		disableOnInit: Boolean,
	},
	computed: {
		e() {
			return this.editor()
		},
		showAsBook() {
			return this.$vuetify.breakpoint.mdAndUp && !this.useSlider
		},
	},
	methods: {
		async calcPageSize(src) {
			let bookContainerWidth = Math.min(this.$el.clientWidth, this.fullWidth ? 4000 : 1400)
			let pageMargin = this.showAsBook && !this.fullWidth ? 50 : 12
			let maxSize = {
				h: window.innerHeight * (this.fullWidth ? 0.9 : 0.7) || 450,
				w: (bookContainerWidth - pageMargin * 2) / (this.showAsBook ? 2 : 1),
			}
			let imgSize = await new Promise((resolve) => {
				let img = new Image()
				img.onload = () => resolve({ w: img.width, h: img.height })
				img.src = src
			})
			let scale = 1
			let { h, w } = imgSize
			if (maxSize.w < w) {
				scale = maxSize.w / w
				h = h * scale
			}
			if (maxSize.h < h) {
				scale = scale * (maxSize.h / h)
			}
			this.e.pageSize = {
				w: Math.round(imgSize.w * scale),
				h: Math.round(imgSize.h * scale),
			}
		},
	},
	mounted() {
		if (this.e.pageSize) return
		this.$nextTick(() => {
			this.calcPageSize(this.e.bookImgs.i[0])
		})
	},
}
</script>

<template>
	<div>
		<FlipBook v-if="e.pageSize && showAsBook" @ready="$emit('ready')" />
		<CarouselBook v-if="e.pageSize && !showAsBook" @ready="$emit('ready')" :disable-on-init="disableOnInit" />
	</div>
</template>

<style lang="scss" scoped>
.big-container {
	min-height: 350px;
	width: 100%;
}
</style>
