<template>
	<div class="page-wrapper">
		<slot />
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	cursor: pointer;

	& > img {
		width: auto;
		height: 100%;
		position: absolute;
	}

	&.front-cover {
		& > img {
			left: unset;
			right: 0;
		}
	}
}
</style>
