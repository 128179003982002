<script>
import _ from 'lodash'

import imgAlertCircle from '../assets/alert-circle.svg'

export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			tabIndex: -1,
			imgAlertCircle,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		tabs() {
			const data = this.e.customization.data
			const { $lang } = this
			return [
				{
					title: $lang('Nombre'),
					get value() {
						return data.name
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name && data.gender && data.age && data.lang
					},
				},
				{
					title: $lang('De parte de'),
					get value() {
						return data.name2
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name2
					},
				},
				{
					title: $lang('Dedicatoria'),
					get value() {
						return data.inscription
					},
					incompleteValue: $lang('Escribe una dedicatoria'),
					get complete() {
						return data.inscription
					},
				},
			]
		},
		dataChecker() {
			let data = this.e.customization.data
			let checker = _.pick(data, ['name', 'name2', 'gender', 'age', 'lang', 'inscription'])
			return JSON.stringify(checker)
		},
	},
	methods: {
		single() {
			// this.e.customization.data.name2multiple = false
			this.$set(this.e.customization.data, 'name2multiple', false)
		},
		multiple() {
			// this.e.customization.data.name2multiple = true
			this.$set(this.e.customization.data, 'name2multiple', true)
		},
	},
	created() {
		// const { name2 } = this.e.customization.data
		// this.e.customization.data.name2 = name2?.toLowerCase() == '[de parte de]' ? '' : name2
		this.$set(this.e.customization.data, 'name2multiple', false)
	},
}
</script>

<template>
	<BookEditor-Tabs :tabs="tabs" :data-checker="dataChecker" v-model="tabIndex">
		<template #item1>
			<div class="pr-md-6 w100">
				<BookEditorInput-TextField
					model-key="name"
					:label="$lang('Este libro personalizado es para')"
					:placeholder="$lang('Nombre')"
				/>
				<BookEditorInput-GenderCharacters />
			</div>
			<div class="pr-md-6 pt-4 pt-md-0 w100">
				<BookEditorInput-TextField
					model-key="age"
					:label="$lang('¿Cuántos años cumple?')"
					:placeholder="$lang('¿Cuántos años cumple?')"
				/>
			</div>
			<div class="pt-4 pt-md-0 w100">
				<BookEditorInput-LangSelect />
			</div>
		</template>
		<template #item2>
			<v-row>
				<v-col cols="12" md="4" class="d-flex flex-column">
					<Button
						@click="single"
						:outlined="e.customization.data.name2multiple !== false"
						class="mb-2"
						large
						color="primary"
					>
						{{ 'De parte de 1 persona' | lang }}
					</Button>
					<Button
						@click="multiple"
						:outlined="e.customization.data.name2multiple !== true"
						large
						color="primary"
					>
						{{ 'De parte de varias personas' | lang }}
					</Button>
				</v-col>
				<v-col cols="12" md="8" class="d-flex flex-column">
					<BookEditorInput-TextField
						model-key="name2"
						:label="$lang('Este libro personalizado es de parte de')"
						:placeholder="$lang('Nombre/s')"
					/>
				</v-col>
			</v-row>
		</template>
		<template #item3>
			<BookEditorTab-Inscription :label="$lang('Los 3 deseos para este cumple son:')" />
		</template>
	</BookEditor-Tabs>
</template>

<style lang="scss" scoped>
.zodiac {
	position: relative;
}
.zodiac__info-icon {
	position: absolute;
	cursor: pointer;
	transition: all 0.3s;
	left: 50%;
	transform: translateX(46px);
	bottom: 10px;
	&:hover {
		transform-origin: center;
		transform: translateX(46px) scale(1.2);
	}
}
.zodiac-info-alert {
	max-width: 360px;
}
</style>
