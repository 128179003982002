<script>
import _ from 'lodash'

export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			tabIndex: -1,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		tabs() {
			let data = this.e.customization.data
			let { $lang } = this
			return [
				{
					title: $lang('Nombre de tu peque'),
					get value() {
						return data.name
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name && data.gender && data.lang && data.color
					},
				},
				{
					title: $lang('De parte de'),
					get value() {
						return data.name2
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name2
					},
				},
				{
					title: $lang('Dedicatoria'),
					get value() {
						return data.inscription
					},
					incompleteValue: $lang('Escribe una dedicatoria'),
					get complete() {
						return data.inscription
					},
				},
			]
		},
		dataChecker() {
			let data = this.e.customization.data
			let checker = _.pick(data, ['name', 'name2', 'gender', 'color', 'lang', 'inscription'])
			return JSON.stringify(checker)
		},
	},
	created() {
		console.log('created', this.e.customization.data)
	},
}
</script>

<template>
	<BookEditor-Tabs :tabs="tabs" :data-checker="dataChecker" v-model="tabIndex">
		<template #item1>
			<div class="pr-md-6 w100">
				<BookEditorInput-TextField
					model-key="name"
					:label="$lang('Este libro personalizado es para')"
					:placeholder="$lang('Nombre')"
				/>
				<BookEditorInput-GenderCharacters />
			</div>
			<div class="pt-4 pt-md-0 w100">
				<BookEditorInput-LangSelect />
				<BookEditorInput-ColorsSelector label="Elige el color de la tapa" />
			</div>
		</template>
		<template #item2>
			<div class="d-flex d-md-block justify-center align-center">
				<BookEditorInput-TextField
					model-key="name2"
					:label="$lang('Este libro personalizado es de parte de')"
					:placeholder="$lang('Nombre')"
				/>
			</div>
		</template>
		<template #item3>
			<BookEditorTab-Inscription />
		</template>
	</BookEditor-Tabs>
</template>
