function getImageElementFromSrc(src) {
	const img = new Image()
	img.src = src
	return new Promise((res, rej) => {
		img.onload = () => {
			res(img)
		}
		img.onerror = rej
	})
}
function getSrcFromFile(file) {
	const reader = new FileReader()
	reader.readAsDataURL(file)
	return new Promise((resolve) => {
		reader.onload = async (e) => {
			resolve(e.target.result)
		}
	})
}
function canvasToFile(canvas, { filename = 'canvas.png', type = 'image/png' } = {}) {
	return new Promise((resolve) => {
		canvas.toBlob((blob) => {
			const file = new File([blob], filename, { type })
			resolve(file)
		})
	})
}

function srcToFile(src, filename) {
	let arr = src.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = window.atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n)

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}

	return new File([u8arr], filename, { type: mime })
}

export { getSrcFromFile, getImageElementFromSrc, canvasToFile, srcToFile }
