const moment = require('moment')

// prettier-ignore
const data = {
	aries: [[21,3], [20,4]],
	tauro: [[21,4], [21,5]],
	geminis: [[22,5], [21,6]],
	cancer: [[22,6], [23,7]],
	leo: [[24,7], [23,8]],
	virgo: [[24,8], [23,9]],
	libra: [[24,9], [23,10]],
	escorpio: [[24,10], [22,11]],
	sagitario: [[23,11], [22,12]],
	capricornio: [[23,12], [20,1]],
	acuario: [[21,1], [19,2]],
	piscis: [[20,2], [20,3]],
}

module.exports = (birthdate) => {
	let date = moment(birthdate)
	let d = date.date()
	let m = date.month() + 1
	for (let [sign, [[fd, fm], [td, tm]]] of Object.entries(data)) {
		if (m == fm && d >= fd) return sign
		if (m == tm && d <= td) return sign
	}
}
