<script>
export default {
	lang: 'books',
	inject: ['editor'],
	computed: {
		e() {
			return this.editor()
		},
		covers() {
			return this.e.config?.covers || []
		},
	},
	mounted() {
		this.$assignDefaults(this.e.customization.data, { gender: null })
	},
}
</script>

<template>
	<div>
		<div>Elige el diseño de la tapa</div>
		<v-item-group
			v-model="e.customization.data.cover"
			mandatory
			class="d-flex flex-wrap align-center justify-center"
		>
			<v-item v-slot="{ active, toggle }" v-for="(cover, i) of covers" :key="i" :value="cover.keyname">
				<div class="gender-selector" :class="{ active }" @click="toggle">
					<div class="gender-selector__icon">
						<Media
							:src="cover.image"
							:max-width="$vuetify.breakpoint.smAndDown ? 80 : 120"
							eager
							style="border-radius: 100px"
						/>
					</div>
					<div class="gender-selector__label">
						{{ cover.title }}
					</div>
				</div>
			</v-item>
		</v-item-group>
	</div>
</template>

<style lang="scss" scoped>
.gender-selector {
	cursor: pointer;
	padding: 4px;
	opacity: 0.7;
	transition: all 0.3s;
	text-align: center;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	.gender-selector__icon {
		transition: all 0.3s;
		border: 4px solid transparent;
		border-radius: 100%;
		// padding: 4px;
		overflow: hidden;
	}
	&:not(.active):hover {
		opacity: 0.8;
		.gender-selector__icon {
			border: 4px solid var(--v-blue-lighten3);
		}
	}
	&.active {
		opacity: 1;
		.gender-selector__icon {
			border: 4px solid var(--v-blue-base);
		}
	}
	.gender-selector__label {
		padding-top: 4px;
		font-weight: bold;
	}
}
</style>
