var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.carouselItems)?_c('div',[_c('Carousel',{ref:"carousel",attrs:{"items":_vm.carouselItems,"options":{
	arrows: false,
	itemWrapperClass: 'd-flex justify-center',
	panelsPerView: 1.1,
},"flicking-options":{
	align: 'center',
	bound: false,
	disableOnInit: _vm.disableOnInit,
}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
var item = ref.item;
return [_c('BookPageWrapper',{class:item.wrapperClass,style:({ width: ((_vm.e.pageSize.w) + "px"), height: ((_vm.e.pageSize.h) + "px") })},[_c('img',{attrs:{"src":item.src}})])]}},{key:"free-slot",fn:function(ref){
var currentIndex = ref.currentIndex;
return [_c('div',{staticClass:"page-count d-flex align-center justify-end"},[(_vm.carouselItems.length != _vm.e.config.totalPages)?[_c('div',{staticClass:"pl-3"},[_c('small',{staticStyle:{"color":"#777"},domProps:{"innerHTML":_vm._s(_vm.$lang('*Aquí te mostramos algunas páginas. El libro contiene <b>{totalPages} páginas totales</b>', { showingPages: _vm.totalPages, totalPages: _vm.e.config.totalPages }))}})]),_c('v-divider',{staticClass:"mx-3",staticStyle:{"border-color":"#999"},attrs:{"vertical":""}})]:_vm._e(),_c('b',{staticClass:"flex-shrink-0"},[_vm._v(_vm._s(currentIndex + 1))]),_vm._v(" / "),_c('b',[_vm._v(_vm._s(_vm.carouselItems.length))])],2)]}}],null,false,3742339077),model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }