<script>
import _ from 'lodash'

import imgAlertCircle from '../assets/alert-circle.svg'

export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			tabIndex: -1,
			imgAlertCircle,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		tabs() {
			const data = this.e.customization.data
			const { $lang } = this
			return [
				{
					title: $lang('Nombres'),
					get value() {
						return `Para ${data.name} de parte de ${data.name2}`
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name && data.name2 && data.name4 && data.lang && data.gender
					},
				},
				{
					title: $lang('Nombre de mi/mis señoritas'),
					get value() {
						return data.name3
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name3
					},
				},
				{
					title: $lang('Dedicatoria'),
					get value() {
						return data.inscription
					},
					incompleteValue: $lang('Escribe una dedicatoria'),
					get complete() {
						return data.inscription
					},
				},
			]
		},
		dataChecker() {
			let data = this.e.customization.data
			let checker = _.pick(data, ['name', 'name2', 'gender', 'age', 'lang', 'inscription'])
			return JSON.stringify(checker)
		},
	},
	methods: {
		single() {
			this.$set(this.e.customization.data, 'name3multiple', false)
		},
		multiple() {
			this.$set(this.e.customization.data, 'name3multiple', true)
		},
	},
	created() {
		this.$set(this.e.customization.data, 'name3multiple', false)
	},
}
</script>

<template>
	<BookEditor-Tabs :tabs="tabs" :data-checker="dataChecker" v-model="tabIndex">
		<template #item1>
			<div class="pr-md-6 w100">
				<BookEditorInput-TextField
					model-key="name"
					:label="$lang('Este libro personalizado es para')"
					:placeholder="$lang('Nombre')"
				/>
				<BookEditorInput-TextField
					model-key="name2"
					:label="$lang('Este libro personalizado es de parte de')"
					:placeholder="$lang('Nombre')"
				/>
				<BookEditorInput-TextField
					model-key="name4"
					:label="$lang('Egresará de')"
					:placeholder="$lang('Nombre del jardín de infantes')"
				/>
			</div>
			<div class="pt-4 pt-md-0 w100">
				<BookEditorInput-LangSelect />
				<BookEditorInput-GenderCharacters />
			</div>
		</template>
		<template #item2>
			<v-row>
				<v-col cols="12" md="4" class="d-flex flex-column">
					<Button
						@click="single"
						:outlined="e.customization.data.name3multiple !== false"
						class="mb-2"
						large
						color="primary"
					>
						{{ 'Una seño' | lang }}
					</Button>
					<Button
						@click="multiple"
						:outlined="e.customization.data.name3multiple !== true"
						large
						color="primary"
					>
						{{ '+ De una seño' | lang }}
					</Button>
				</v-col>
				<v-col cols="12" md="8" class="d-flex flex-column">
					<BookEditorInput-TextField
						model-key="name3"
						:label="$lang('Nombre/s de mi/mis señoritas')"
						:placeholder="$lang('Ejemplo: Verónica, Vitu y Carla')"
					/>
				</v-col>
			</v-row>
		</template>
		<template #item3>
			<BookEditorTab-Inscription />
		</template>
	</BookEditor-Tabs>
</template>

<style lang="scss" scoped>
.zodiac {
	position: relative;
}
.zodiac__info-icon {
	position: absolute;
	cursor: pointer;
	transition: all 0.3s;
	left: 50%;
	transform: translateX(46px);
	bottom: 10px;
	&:hover {
		transform-origin: center;
		transform: translateX(46px) scale(1.2);
	}
}
.zodiac-info-alert {
	max-width: 360px;
}
</style>
