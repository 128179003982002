import { getImageElementFromSrc } from '@/__shared/utils/lib_imageUtils'

export default async function grayscale(src) {
	const image = await getImageElementFromSrc(src)
	const myCanvas = document.createElement('canvas')
	const myCanvasContext = myCanvas.getContext('2d')
	const imgWidth = image.width
	const imgHeight = image.height
	myCanvas.width = imgWidth
	myCanvas.height = imgHeight
	myCanvasContext.drawImage(image, 0, 0)
	const imageData = myCanvasContext.getImageData(0, 0, imgWidth, imgHeight)
	for (let i = 0; i < imageData.height; i++) {
		for (let j = 0; j < imageData.width; j++) {
			const index = i * 4 * imageData.width + j * 4
			const red = imageData.data[index]
			const green = imageData.data[index + 1]
			const blue = imageData.data[index + 2]
			const alpha = imageData.data[index + 3]
			const average = (red + green + blue) / 3
			imageData.data[index] = average
			imageData.data[index + 1] = average
			imageData.data[index + 2] = average
			imageData.data[index + 3] = alpha
		}
	}
	myCanvasContext.putImageData(imageData, 0, 0, 0, 0, imageData.width, imageData.height)
	return myCanvas.toDataURL()
}
