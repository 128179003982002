<script>
import { get } from 'vuex-pathify'
import _ from 'lodash'
export default {
	lang: 'books',
	props: {
		variantId: String,
		productId: String,
		customizationKey: String,
		config: Object,
	},
	data() {
		return {
			e: {
				loading: {
					addToCart: false,
					submit: false,
					data: false,
				},
				error: false,
				customization: {
					data: {},
				},
				customizationFiles: {},
				config: {},
				editing: false,
				bookData: null,
				bookImgs: null,
				validation: {},
				dataChanged: false,
				dataCompleted: false,
				previewReady: false,
				firstPreviewReady: false,
				pageSize: null,
				dialog: false,
				tab: -1,
			},
		}
	},
	computed: {
		urlCustomizationId: get('route@query.customizationId'),
		show() {
			return {
				error: this.e.error,
				loader: !this.e.error && !this.e.previewReady,
				preview: !this.e.error && !this.e.loading.data,
				oldTemplate: this.customizationKey ? this.customizationKey != 'mothers-day' : false,
				newTemplate: this.customizationKey == 'mothers-day',
			}
		},
		inputsComponentName() {
			return `BookEditorCustomizationInputs-${this.customizationKey}`
		},
	},
	watch: {
		urlCustomizationId() {
			this.loadBookCustomization()
			this.e.customization.id = this.urlCustomizationId
		},
	},
	provide() {
		return {
			editor: () => this.e,
			editorSubmit: () => this.submit(),
			editorAddToCart: () => this.addToCart(),
		}
	},
	methods: {
		postBookCustomization(onSuccess) {
			return this.$shopApi.post({
				url: '/book-customization',
				onValidation: ({ validation }) => (this.e.validation = validation),
				data: {
					variantId: this.variantId,
					customization: this.e.customization,
				},
				files: this.e.customizationFiles,
				onError: (e) => {
					this.e.loading.submit = false
				},
				onSuccess,
			})
		},
		loadBookCustomization() {
			try {
				this.e.error = false
				this.e.previewReady = false
				return this.$shopApi.get({
					url: `/book-customization/${this.e.customization.id}`,
					loading: (v) => (this.e.loading.data = v),
					done: ({ data, success, message }) => {
						if (success) {
							this.e.customization = {}
							this.$assignDeep(this.e, data)
							this.e.customizationFiles = {}
						} else {
							if (message?.code == 'item_not_found') {
								this.$router.replace({ name: 'home' })
							} else {
								this.e.error = true
							}
						}
					},
				})
			} catch (err) {
				this.e.error = true
			}
		},

		closeDialog() {
			this.e.dialog = false
		},
		async submit() {
			if (!this.e.dataChanged && this.e.firstPreviewReady) {
				this.e.dialog = true
				return
			}
			this.e.loading.submit = true
			this.e.customization.data.finalStep = true
			await this.postBookCustomization(async ({ data }) => {
				this.e.dialog = true
				const { customizationId } = data
				this.e.customization.id = customizationId
				this.$router.push({
					name: this.$route.name,
					query: { customizationId },
					params: { savePosition: true },
				})
				await this.loadBookCustomization()
				this.e.loading.submit = false
			})
		},
		async addToCart() {
			this.e.customization.data.finalStep = true
			this.e.loading.addToCart = true

			await this.postBookCustomization(({ data }) => {
				this.e.customization.id = data.customizationId
			})

			return this.$shop.addToCart({
				productId: this.productId,
				variantId: this.variantId,
				qty: 1,
				sum: false,
				customization: this.e.customization,
				showUpsells: !this.e.editing,
				loading: (v) => (this.e.loading.addToCart = v),
				onValidation: ({ validation }) => (this.e.validation = validation),
				onSuccess: ({ data }) => {
					if (!data.upsells) {
						this.$shop.goShop().then(() => {
							this.$shop.showCart()
						})
					}
				},
			})
		},
		onPreviewReady() {
			this.e.previewReady = true
			this.e.firstPreviewReady = true
			this.e.loading.submit = false
			this.e.dataChanged = false
		},
		openFirstTab() {
			this.$nextTick(() => {
				this.e.tab = 0
				this.$nextTick(() => {
					if (!document.querySelector('.c-tabs-items')) return
					const rect = document.querySelector('.c-tabs-items').getBoundingClientRect()
					const top = rect.top + window.scrollY - 60
					window.scrollTo({ top, behavior: 'smooth' })
				})
			})
		},
	},
	async created() {
		this.e.config = this.config
		this.e.customization.key = this.customizationKey
		if (this.urlCustomizationId) {
			this.$nextTick(() => {
				this.$vuetify.goTo('.scroll-point', { offset: 60, duration: 1200 })
			})
			this.e.customization.id = this.urlCustomizationId
			await this.loadBookCustomization()
			this.openFirstTab()
		}
	},
}
</script>

<template>
	<div class="scroll-point">
		<v-dialog v-model="e.dialog" :persistent="show.loader" class="overflow-hidden">
			<div class="lightyellow relative overflow-hidden pb-4">
				<Button fab :x-small="$vuetify.breakpoint.smAndDown" elevation="0" color="darkblue"
					class="absolute-top-right" @click="closeDialog" v-if="show.preview && !show.loader">
					<v-icon large>mdi-chevron-down</v-icon>
				</Button>
				<div class="d-flex align-center justify-center w100 py-4 font-3 error--text px-16" style="min-height: 350px"
					v-if="show.error">
					<div class="d-flex flex-column justify-center align-center">
						<div>
							{{ $lang('Ha ocurrido un error al procesar la previsualización del libro.') }}
						</div>
						<Button class="mt-4" color="info" large @click="closeDialog">
							{{ $lang('Volver') }}
						</Button>
					</div>
				</div>
				<div :style="{ opacity: e.previewReady ? 1 : 0 }" class="pt-4 d-flex align-center justify-center">
					<img src="../assets/asiSeraTuLibro2.png" class="formitas mr-6" />
					<span class="darkblue--text font-weight-bold text-center font-3">
						{{
			$vuetify.breakpoint.xs
				? $lang('Así será tu libro')
				: $lang('Así será tu libro personalizado')
		}}
					</span>
					<img src="../assets/asiSeraTuLibro.png" class="formitas ml-6" />
				</div>
				<BookPreviewBallsLoader class="mb-4" v-if="show.loader" ref="ballsLoader" />
				<BookPreview @ready="onPreviewReady" class="mt-4" ref="bookPreview" v-if="show.preview" />
				<div class="w-full d-flex justify-center pt-12 pt-md-6" v-if="show.preview && !show.loader">
					<Button class="mr-2" color="green" large @click="closeDialog">
						{{ $lang('Volver') }}
					</Button>
					<Button color="orange" large @click="addToCart" :loading="e.loading.addToCart">
						{{ e.editing ? $lang('Guardar y Comprar') : $lang('Agregar al carrito') }}
						<v-icon class="ml-2">mdi-cart-outline</v-icon>
					</Button>
				</div>
			</div>
		</v-dialog>
		<component :is="inputsComponentName" />
	</div>
</template>

<style scoped>
.formitas {
	width: 50px;
	height: 50px;
	object-fit: contain;
}

@media screen and (max-width: 600px) {
	.formitas {
		width: 35px;
		height: 35px;
	}
}

.absolute-top-right {
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 1;
}
</style>
