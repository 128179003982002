<script>
//import bypass from '@/__shared/utils/mixin_modelBypass'
const langsmap = { es: ['esar', 'esne'], en: ['ing'], pt: ['port'] }

export default {
	//mixins: [bypass('value', 'input', String)],
	lang: 'books',
	props: ['label'],
	inject: ['editor'],

	computed: {
		e() {
			return this.editor()
		},
	},
	mounted() {
		let langsmap = { es: ['esar', 'esne'], en: ['ing'], pt: ['port'] }
		let iso = this.$srv('lang.iso')
		iso = iso.includes('_') ? iso.split('_')[0] : iso
		let defaults = langsmap[iso || 'es']
		console.log(this.$srv('lang.iso'))
		let availableValues = this.e.config.langs.map((x) => x.value)
		let defaultValue = defaults.find((value) => availableValues.includes(value)) || availableValues[0]
		this.$assignDefaults(this.e.customization.data, { lang: null })
		this.$set(this.e.customization.data, 'lang', this.e.customization.data.lang || defaultValue)
	},
}
</script>

<template>
	<div class="input-container">
		<div class="primary--text">{{ label || $lang('Idioma del libro') }}</div>
		<Select
			v-model="e.customization.data.lang"
			:validator-key="`customization.data.lang`"
			:placeholder="$lang('Idioma')"
			class="darkblue--text font-weight-bold font-2 input-text-center"
			append-icon="mdi-pencil-box-outline"
			:items="e.config.langs"
			solo
			v-bind="$attrs"
		/>
	</div>
</template>

<style scoped>
.input-container {
	height: 100px;
}
</style>
