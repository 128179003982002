<script>
import _ from 'lodash'
export default {
	lang: 'books',
	inject: ['editor', 'editorSubmit', 'editorAddToCart'],
	data() {
		return {
			tabIndex: -1,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		// show() {
		// 	return {
		// 		addToCartBtn: !this.e.dataChanged && this.e.dataCompleted,
		// 		submitBtnDisabled: !this.e.dataCompleted,
		// 		submitBtn: this.e.dataChanged || !this.e.dataCompleted,
		// 	}
		// },
	},
}
</script>

<template>
	<portal to="Product.actions">
		<div class="d-flex flex-column">
			<Button
				class="action-btn mb-2"
				color="orange"
				large
				@click="editorAddToCart"
				:loading="e.loading.addToCart"
			>
				{{ $langText('Agregar al carrito') }}
				<v-icon class="ml-2">mdi-cart-outline</v-icon>
			</Button>
			<Button class="action-btn" color="green" large @click="editorSubmit" :loading="e.loading.submit">
				<div class="preline">
					{{ $langText('Ver mi libro') }}
				</div>
				<v-icon class="ml-3">mdi-eye</v-icon>
			</Button>
		</div>
	</portal>

	<!-- <BookEditor-Tabs :tabs="tabs" :data-checker="dataChecker" v-model="tabIndex">
		<template #item1>
			<div>
				<div>
					<BookEditorInput-TextField
						model-key="name"
						:label="$lang('Este libro personalizado es para')"
						:placeholder="$lang('Nombre')"
					/>
				</div>
				<div class="pt-4">
					<BookEditorInput-LangSelect />
				</div>
			</div>
		</template>
		<template #item2>
			<BookEditorTab-Inscription />
		</template>
	</BookEditor-Tabs> -->
</template>
