<script>
export default {
	lang: 'books',
	props: ['modelKey', 'label'],
	inject: ['editor'],
	computed: {
		e() {
			return this.editor()
		},
	},
	mounted() {
		this.$assignDefaults(this.e.customization.data, { [this.modelKey]: null })
	},
}
</script>

<template>
	<div class="input-container">
		<div class="primary--text">{{ label }}</div>
		<DatePickerDialog
			v-model="e.customization.data[modelKey]"
			:validator-key="`customization.data.${modelKey}`"
			:placeholder="$lang('dd/mm/aaaa')"
			date-text-format="DD/MM/YYYY"
			textfield-class="darkblue--text font-weight-bold font-2 input-text-center"
			:textfield-attrs="{ appendIcon: 'mdi-pencil-box-outline', solo: true, elevation: 1 }"
			v-bind="$attrs"
		/>
	</div>
</template>

<style scoped>
.input-container {
	height: 100px;
}
</style>
