<script>
export default {
	lang: 'books',
	props: ['label'],
	inject: ['editor'],
	computed: {
		e() {
			return this.editor()
		},
		inscriptionPresets() {
			const lang = this.e.customization.data?.lang || 'esne'
			return this.e.config[`inscriptionPresets_${lang}`] || []
		},
		initialInscription() {
			const lang = this.e.customization.data?.lang || 'esne'
			return this.e.config[`initialInscription_${lang}`] || ''
		},
	},
	mounted() {
		console.log('config', this.e.config)
		this.$assignDefaults(this.e.customization.data, { inscription: null })
		this.$watch('e.customization.data.inscription', (value) => {
			if (value) {
				console.log(value)
				if (value.match(/(\r\n|\n|\r)/gm)) {
					this.$nextTick(() => {
						this.e.customization.data.inscription = value.replace(/(\r\n|\n|\r)/gm, ' ').trim()
					})
				} else if (value.match(/[\s]{2,}/g)) {
					this.$nextTick(() => {
						this.e.customization.data.inscription = value.replace(/[\s]{2,}/g, ' ')
					})
				}
			}
		})
	},
}
</script>

<template>
	<div class="d-flex flex-column flex-md-row justify-center">
		<div class="mr-md-8 text-center text-md-left">
			<div class="primary--text">{{ label || $lang('Regala una dedicatoria llena de amor') }}</div>
			<div
				:style="{
					width: $vuetify.breakpoint.xs ? 'auto' : '450px',
					maxWidth: '100%',
					margin: 'auto',
				}"
			>
				<Textarea
					v-model="e.customization.data.inscription"
					validator-key="customization.data.inscription"
					class="darkblue--text font-weight-bold font-2"
					:counter="e.config.inscriptionMaxLen"
					:maxlength="e.config.inscriptionMaxLen"
					append-icon="mdi-pencil-box-outline"
					:placeholder="initialInscription"
					elevation="1"
					solo
					height="140px"
				/>
			</div>
		</div>
		<div class="text-center text-md-left">
			<div class="primary--text" v-if="inscriptionPresets.length">
				{{ $lang('¿No se te ocurre nada? Te dejamos algunas ideas...') }}
			</div>
			<div class="mt-2" v-if="inscriptionPresets.length">
				<div class="pr-2 pb-2" v-for="(preset, i) of inscriptionPresets" :key="i">
					<Button
						:color="e.customization.data.inscription == preset.text ? 'darkblue' : 'primary'"
						:outlined="e.customization.data.inscription != preset.text"
						@click="e.customization.data.inscription = preset.text"
						class="px-5 py-2 preset-btn"
					>
						{{ preset.text }}
					</Button>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.preset-btn {
	height: auto !important;
	max-width: 450px;
}
.preset-btn ::v-deep .v-btn__content {
	text-align: left;
}
</style>
