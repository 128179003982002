<script>
export default {
	lang: 'books',
	inject: ['editor'],
	props: {
		label: {
			type: String,
			default: 'Elige el color de la historia',
		},
	},
	computed: {
		e() {
			return this.editor()
		},
	},
	mounted() {
		this.$assignDefaults(this.e.customization.data, { gender: null })
	},
}
</script>

<template>
	<div>
		<div>{{ label }}</div>
		<div>
			<v-item-group
				v-model="e.customization.data.color"
				mandatory
				class="d-flex align-center justify-center"
			>
				<v-item
					v-for="color of e.config.colors"
					:key="color.colorId"
					v-slot="{ active, toggle }"
					:value="color.colorId"
				>
					<div class="color-selector" :class="{ active }" @click="toggle">
						<div
							class="color-selector__circle"
							:style="{
								backgroundColor: color.hex,
								borderColor: active ? color.borderHex : color.hex,
							}"
						></div>
						<div class="color-selector__label">
							{{ color.text }}
						</div>
					</div>
				</v-item>
			</v-item-group>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.color-selector {
	cursor: pointer;
	padding: 4px;
	transition: all 0.3s;
	text-align: center;
	.color-selector__circle {
		transition: all 0.3s;
		border: 4px solid;
		border-color: transparent;
		border-radius: 100%;
		padding: 4px;
		width: 96px;
		height: 96px;
		overflow: hidden;
	}
	&:not(.active):hover {
		opacity: 0.8;
	}
	.color-selector__label {
		padding-top: 4px;
		font-weight: bold;
	}
}
</style>
