<script>
import _ from 'lodash'
export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			tabIndex: -1,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		tabs() {
			let data = this.e.customization.data
			let { $lang } = this
			return [
				{
					title: $lang('Dedicatoria'),
					get value() {
						return data.inscription
					},
					incompleteValue: $lang('Escribe una dedicatoria'),
					get complete() {
						return data.inscription
					},
				},
			]
		},
		dataChecker() {
			let data = this.e.customization.data
			let checker = _.pick(data, ['inscription'])
			return JSON.stringify(checker)
		},
	},
	created() {
		this.e.customization.data.lang = 'esne'
	},
}
</script>

<template>
	<BookEditor-Tabs :tabs="tabs" :data-checker="dataChecker" v-model="tabIndex">
		<template #item1>
			<div>
				<BookEditorTab-Inscription />
			</div>
		</template>
	</BookEditor-Tabs>
</template>
