<script>
export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			lastUserImage: null,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
	},
	watch: {
		'e.customizationFiles.userImage'(value) {
			if (value) {
				this.lastUserImage = value
				this.e.customization.data.userImageName = value.name
			}
		},
	},
	methods: {
		toggleDefaultUserImage() {
			let value = !this.e.customization.data.useDefaultUserImage
			this.e.customization.data.useDefaultUserImage = value
			this.e.customizationFiles.userImage = value ? null : this.lastUserImage
		},
	},
	mounted() {
		this.$assignDefaults(this.e.customization.data, {
			useDefaultUserImage: false,
			userImage: null,
			userImageName: null,
		})
		this.$assign(this.e.customizationFiles, {
			userImage: null,
		})
	},
}
</script>

<template>
	<div class="d-flex flex-column justify-center align-center">
		<BookEditorInput-Image
			v-model="e.customizationFiles.userImage"
			:initial-src="!e.customization.data.useDefaultUserImage && e.customization.data.userImage"
			v-show="!e.customization.data.useDefaultUserImage"
			:options="{
				minWidth: Number(e.config.userImageWidth),
				minHeight: Number(e.config.userImageHeight),
			}"
		/>
		<Media
			:src="e.config.defaultUserImage"
			height="280"
			contain
			v-show="e.customization.data.useDefaultUserImage"
			eager
		/>
		<div class="pt-6 pb-2">
			<Button
				:text="!e.customization.data.useDefaultUserImage"
				small
				color="darkblue"
				@click="toggleDefaultUserImage"
			>
				<v-icon class="mr-2">
					{{
						e.customization.data.useDefaultUserImage
							? 'mdi-checkbox-marked-circle'
							: 'mdi-checkbox-blank-circle-outline'
					}}
				</v-icon>
				{{ $lang('Usar foto predeterminada') }}
			</Button>
		</div>
	</div>
</template>
