<script>
export default {
	lang: 'books',
	props: {
		tabs: {
			type: Array,
			required: true,
		},
		tabIndex: Number,
		dataChecker: String,
	},
	inject: ['editor', 'editorSubmit', 'editorAddToCart'],
	model: {
		prop: 'tabIndex',
		event: 'input',
	},
	data() {
		return {
			overlayBottomHeight: 0,
			disabled: false,
			savedChecker: null,
			loading: {
				addToCart: false,
				submit: false,
			},
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		show() {
			return {
				addToCartBtn: !this.e.dataChanged && this.e.dataCompleted,
				submitBtnDisabled: !this.e.dataCompleted,
				submitBtn: this.e.dataChanged || !this.e.dataCompleted,
			}
		},
		vh() {
			return window.innerHeight * 0.01
		},
	},
	watch: {
		'e.customization.data': {
			deep: true,
			handler() {
				this.checkChanges()
			},
		},
		'e.customizationFiles': {
			deep: true,
			handler() {
				this.checkChanges()
			},
		},
		'e.previewReady'(value) {
			if (value) {
				this.disabled = false
				this.saveChanges()
				this.checkChanges()
			}
		},
		'e.error'(value) {
			if (value) {
				this.disabled = false
			}
		},
		'e.validation'(value) {
			if (value && Object.keys(value).length) {
				this.disabled = false
				this.toggleTab(0, true)
			}
		},
		'e.tab'(value, oldValue) {
			this.toggleTab(value, true)
		},
		tabIndex(value) {
			this.toggleTab(value, true)
		},
	},
	methods: {
		toggleTab(i, fromWatch) {
			if (fromWatch && this.tabIndex == i) return
			let newValue = this.tabIndex == i ? -1 : i
			if (newValue != this.tabIndex && this.tabIndex >= 0) {
				this.$set(this.tabs[this.tabIndex], 'seen', true)
			}
			this.e.tab = newValue
			this.$emit('input', newValue)
		},
		saveChanges() {
			this.savedChecker = this.dataChecker
		},
		checkChanges() {
			if (this.savedChecker != this.dataChecker) {
				this.e.dataChanged = true
			}
			this.e.dataCompleted = !this.tabs.find((tab) => !tab.complete)
		},
		addToCart() {
			this.disabled = true
			this.saveChanges()
			this.editorAddToCart()
		},
		async submit() {
			if (this.e.dataChanged && !this.e.firstPreviewReady) {
				this.disabled = true
			}
			this.saveChanges()
			await this.editorSubmit()
		},
		getTabIconColor(tab) {
			return tab.complete ? 'green' : !tab.complete && tab.seen ? 'error' : 'green'
		},
	},
	mounted() {
		this.saveChanges()
		this.checkChanges()
		this.$nextTick(() => {
			const { bottom } = this.$refs.tabsContainer.getBoundingClientRect()
			this.overlayBottomHeight = `${document.body.clientHeight - bottom}px`
		})
	},
}
</script>

<template>
	<Validator :validation="e.validation">
		<div class="relative px-md-4 py-8 primary" :style="{ zIndex: 100 }" ref="tabsContainer">
			<div class="d-md-none text-center white--text font-2 pb-4">Ingresa estos datos</div>
			<Container width="1300px" class="d-flex flex-column flex-md-row align-center pa-0 w100">
				<div class="w100">
					<div
						class="c-tabs d-flex flex-column flex-md-row"
						:class="{ 'c-tabs--disabled': disabled || e.loading.data }"
					>
						<v-overlay absolute :value="e.loading.data" color="rgba(0,0,0,.3)">
							<v-progress-circular :size="70" :width="7" color="primary" indeterminate />
						</v-overlay>
						<div
							v-for="(tab, i) of tabs"
							:key="i"
							class="c-tabs__tab d-flex pa-2 pb-3 align-center rounded relative w100 bg-lightyellow mr-1 mb-1 mb-md-0"
							:class="{ 'c-tabs__tab--active': tabIndex === i }"
							:style="{
								borderBottom: tab.complete
									? '6px solid var(--v-green-base) !important'
									: !tab.complete && tab.seen
									? '6px solid var(--v-error-base) !important'
									: '',
								paddingBottom: !tab.seen ? '5px' : '0px',
							}"
							@click="toggleTab(i)"
							:ref="`tab${i}`"
						>
							<div class="arrow-up" v-show="tabIndex === i"></div>
							<div class="mr-2">
								<v-icon large v-if="tab.complete" color="green">mdi-check-circle</v-icon>
								<v-icon large v-else-if="tab.seen" color="error">mdi-close-circle</v-icon>
								<v-icon large v-else class="opacity-text" color="primary">mdi-check-circle</v-icon>
							</div>
							<div class="c-tabs__tab__text pr-4">
								<div class="c-tabs__tab__text__subtitle primary--text font-weight-black">
									{{ tab.title }}
								</div>
								<div
									class="c-tabs__tab__text__title line-clamp-1"
									style="word-break: break-all"
									:class="tab.complete ? 'primary--text' : tab.seen ? 'error--text' : 'opacity-text'"
								>
									{{ tab.complete ? tab.value : tab.incompleteValue }}
								</div>
							</div>
							<v-spacer></v-spacer>
							<div class="c-tabs__tab__arrowIcon">
								<v-btn icon>
									<v-icon color="#999">
										{{ $vuetify.breakpoint.smAndDown ? 'mdi-chevron-right' : 'mdi-chevron-up' }}
									</v-icon>
								</v-btn>
							</div>
						</div>
						<div
							class="pl-md-4 pt-6 pt-md-0 d-flex flex-column align-center justify-center justify-md-end"
						>
							<Button
								:disabled="show.submitBtnDisabled"
								class="action-btn mb-2"
								color="orange"
								large
								@click="addToCart"
								:loading="e.loading.addToCart"
							>
								{{ e.editing ? $langText('Guardar y Comprar') : $langText('Agregar al carrito') }}
								<v-icon class="ml-2">mdi-cart-outline</v-icon>
							</Button>
							<Button
								:disabled="show.submitBtnDisabled"
								class="action-btn"
								color="green"
								large
								@click="submit"
								:loading="e.loading.submit"
							>
								<div class="preline">
									{{ e.editing ? $langText('Guardar y Ver mi libro') : $langText('Ver mi libro') }}
								</div>
								<v-icon class="ml-3">mdi-eye</v-icon>
							</Button>
						</div>
					</div>
					<div class="c-tabs-items lightgreen pa-4" v-show="tabIndex >= 0">
						<Container width="1300" class="pa-4 pa-md-0">
							<div
								class="c-tabs-items__item pa-sm-6"
								v-for="(_, i) of tabs"
								:key="i"
								v-show="tabIndex === i"
							>
								<div class="d-md-block d-flex justify-center">
									<Button
										fab
										small
										elevation="0"
										color="darkblue"
										class="c-tabs-items__item__arrow-btn d-none d-md-block"
										@click="toggleTab(-1)"
									>
										<v-icon large>mdi-chevron-down</v-icon>
									</Button>
									<div
										class="d-flex flex-column flex-md-row align-md-start align-center justify-center w100 primary--text"
									>
										<slot :name="`item${i + 1}`" />
									</div>
									<Button
										v-if="$vuetify.breakpoint.smAndDown"
										class="c-tabs-items__item__mobile-accept"
										color="darkblue"
										tile
										x-large
										block
										:rounded="false"
										@click="toggleTab(-1)"
									>
										{{ $lang('Aceptar') }}
									</Button>
								</div>
							</div>
						</Container>
					</div>
					<div class="c-overlay top" v-if="tabIndex >= 0" @click="toggleTab(-1)" />
					<div
						class="c-overlay bottom"
						v-if="tabIndex >= 0"
						:style="{ height: overlayBottomHeight }"
						@click="toggleTab(-1)"
					/>
				</div>
			</Container>
		</div>
	</Validator>
</template>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
	background-color: rgb(223 215 206) !important;
}

::v-deep .v-btn.v-btn--disabled {
	color: rgba(0, 0, 0, 0.5) !important;
}

.bg-lightyellow {
	background-color: var(--v-lightyellow-base);
}

.action-btn {
	min-width: 265px !important;
	max-width: 100% !important;
	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		width: 100%;
	}
}
.opacity-text {
	opacity: 0.5;
	color: var(--v-primary-base);
}
.c-tabs {
	&--disabled {
		pointer-events: none;
		opacity: 0.8;
	}
	&--disabled * {
		pointer-events: none;
	}
	&__tab {
		border: 1px solid #ccc;
		transition: filter 0.3s;
		cursor: pointer;
		transition: transform 0.3s;
		&--active {
			border-color: var(--v-green-base);
		}
		&:active {
			transform: scale(0.99);
		}

		&__text {
			&__subtitle {
				color: #999;
			}
		}
	}
}
.c-tabs-items {
	position: absolute;
	top: 0;
	left: 0;
	transform: translateY(-100%);
	width: 100%;
	z-index: 101;
	&__item {
		width: 100%;
		&__arrow-btn {
			position: absolute;
			top: 15px;
			right: 15px;
		}
		&__mobile-accept {
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100%;
			cursor: pointer;
			// transform: translateY(-100%);
		}
	}
	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		transform: none;
		padding-top: 40px;
		padding-bottom: 80px;
		overflow-y: auto;
		overflow-x: hidden;
	}
}
.arrow-up {
	width: 0;
	height: 0;
	border-left: 37px solid transparent;
	border-right: 37px solid transparent;
	border-top: 37px solid var(--v-lightgreen-base);
	position: absolute;
	left: 0;
	top: -2px;
	transform: translateY(-85%);
}
.c-overlay {
	position: absolute;
	left: 0;
	background-color: rgba(0, 0, 0, 0.4);
	width: 100%;
	z-index: 100;
	&.top {
		top: 0;
		transform: translateY(-100%);
		height: 500vh;
	}
	&.bottom {
		bottom: 0;
		height: 436px;
		transform: translateY(100%);
	}
}
.c-tabs-items ::v-deep input,
.c-tabs-items ::v-deep .v-select__selection {
	line-height: 28px;
}
</style>
