<script>
import { get } from 'vuex-pathify'
import _ from 'lodash'
export default {
	lang: 'books',
	metaInfo() {
		return { title: this.$lang('Personaliza tu libro') }
	},
	pageView() {
		return {
			location: '/book-preview',
		}
	},
	data() {
		return {
			e: {
				loading: {
					addToCart: false,
					submit: false,
					data: true,
				},
				error: false,
				productId: null,
				variantId: null,
				customization: {
					data: {},
				},
				customizationFiles: {},
				config: {},
				editing: false,
				bookData: null,
				bookImgs: null,
				validation: {},
				dataChanged: false,
				dataCompleted: false,
				previewReady: false,
				firstPreviewReady: false,
				pageSize: null,
			},
		}
	},
	computed: {
		urlCustomizationId: get('route@params.customizationId'),
		show() {
			return {
				error: this.e.error,
				loader: !this.e.error && !this.e.previewReady,
				preview: !this.e.error && !this.e.loading.data,
				oldTemplate: this.e.customization.key ? this.e.customization.key != 'mothers-day' : false,
				newTemplate: this.e.customization.key == 'mothers-day',
				inputs: this.e.firstPreviewReady,
			}
		},
		inputsComponentName() {
			return `BookEditorCustomizationInputs-${this.e.customization.key}`
		},
	},
	watch: {
		urlCustomizationId() {
			this.e.firstPreviewReady = false
			this.loadBookCustomization()
		},
	},
	provide() {
		return {
			editor: () => this.e,
			editorSubmit: () => this.submit(),
			editorAddToCart: () => this.addToCart(),
		}
	},
	methods: {
		async loadBookCustomization() {
			try {
				this.e.error = false
				this.e.previewReady = false
				this.$nextTick(() => {
					if (this.e.firstPreviewReady) {
						this.$vuetify.goTo(this.$refs.ballsLoader, { duration: 1200, offset: 30 })
					}
				})

				await this.$shopApi.get({
					url: `/book-customization/${this.urlCustomizationId}`,
					loading: (v) => (this.e.loading.data = v),
					done: ({ data, success, message }) => {
						if (success) {
							this.e.customization = {}
							this.$assignDeep(this.e, data)
							this.e.customizationFiles = {}
						} else {
							if (message?.code == 'item_not_found') {
								this.$router.replace({ name: 'home' })
							} else {
								this.e.error = true
							}
						}
					},
				})
			} catch (err) {
				console.log(err)
				this.e.error = true
			}
		},
		async submit() {
			this.e.customization.data.finalStep = true
			this.e.loading.submit = true
			await this.$shopApi.post({
				url: '/book-customization',
				loading: false,
				onValidation: ({ validation }) => (this.e.validation = validation),
				data: {
					variantId: this.e.variantId,
					customization: this.e.customization,
				},
				files: this.e.customizationFiles,
				onSuccess: async () => {
					await this.loadBookCustomization()
				},
				onError: () => {
					this.e.loading.submit = false
				},
			})
		},
		async addToCart() {
			this.e.customization.data.finalStep = true
			return this.$shop.addToCart({
				productId: this.e.productId,
				variantId: this.e.variantId,
				qty: 1,
				sum: false,
				customization: this.e.customization,
				showUpsells: !this.e.editing,
				loading: (v) => (this.e.loading.addToCart = v),
				onValidation: ({ validation }) => (this.e.validation = validation),
				onSuccess: ({ data }) => {
					if (!data.upsells) this.$shop.goShop()
				},
			})
		},
		onPreviewReady() {
			this.e.previewReady = true
			this.e.firstPreviewReady = true
			this.e.loading.submit = false
			this.e.dataChanged = false
			this.$nextTick(() => {
				setTimeout(() => {
					this.$vuetify.goTo(this.$refs.bookPreview, { duration: 1200, offset: 40 })
				}, 100)
			})
		},
	},
	mounted() {
		this.loadBookCustomization()
	},
}
</script>

<template>
	<div class="page-bg-container">
		<div
			class="d-flex align-center justify-center w100 py-4 font-3 error--text px-16"
			style="min-height: 350px"
			v-if="show.error"
		>
			{{ $lang('Ha ocurrido un error al procesar la previsualización del libro.') }}
		</div>
		<div :style="{ opacity: e.previewReady ? 1 : 0 }" class="pt-8 d-flex align-center justify-center">
			<img src="../assets/asiSeraTuLibro2.png" class="formitas mr-6" />
			<span class="darkblue--text font-weight-bold text-center font-3">
				{{
					$vuetify.breakpoint.xs ? $lang('Así será tu libro') : $lang('Así será tu libro personalizado')
				}}
			</span>
			<img src="../assets/asiSeraTuLibro.png" class="formitas ml-6" />
		</div>
		<BookPreviewBallsLoader class="mb-4" v-if="show.loader" ref="ballsLoader" />
		<BookPreview @ready="onPreviewReady" class="mt-4" ref="bookPreview" v-if="show.preview" />
		<component v-if="show.inputs" class="mt-10 mt-md-0" :is="inputsComponentName" />
	</div>
</template>

<style scoped>
.page-bg-container {
	padding-bottom: 20px;
	min-height: 100vh;
}
.formitas {
	width: 50px;
	height: 50px;
	object-fit: contain;
}
@media screen and (max-width: 600px) {
	.formitas {
		width: 35px;
		height: 35px;
	}
}
</style>
