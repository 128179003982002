<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
	name: 'CropperForBook',
	props: ['value', 'options', 'displayWidth'],
	components: {
		Cropper,
	},
	data() {
		return {
			aspectRatio: this.options.width / this.options.height,
			loading: true,
			src: '',
		}
	},
	computed: {
		cSize() {
			let w = this.displayWidth
			let h = (this.options.height * w) / this.options.width
			return { w, h }
		},
		cropperStyles() {
			/*return {
				height: this.height + 'px',
				width: this.width + 'px',
				minHeight: this.height + 'px',
				minWidth: this.width + 'px',
			}*/
			return {
				height: this.cSize.h + 'px',
				width: this.cSize.w + 'px',
				minHeight: this.cSize.h + 'px',
				minWidth: this.cSize.w + 'px',
			}
		},
	},
	methods: {
		change(result) {
			//this.$emit('cut', result)
		},
		cropperReady() {
			this.loading = false
			this.$emit('cropper-ready', this.$refs.cropper)
		},
		init() {
			if (typeof this.value === 'string') {
				this.src = this.value
				return
			}
			const reader = new FileReader()
			reader.onload = (e) => {
				this.src = e.target.result
			}
			reader.readAsDataURL(this.value)
		},
		sizeRestrictionsAlgorithm() {
			return {
				minWidth: this.options.width,
				minHeight: this.options.height,
			}
		},
	},
	watch: {
		value() {
			this.init()
		},
	},
	created() {
		if (this.value) this.init()
	},
}
</script>

<template>
	<div class="d-flex align-center relative cropper-border-dash" v-if="src" v-show="!loading">
		<cropper
			background-class="cropperBackground"
			:style="cropperStyles"
			@change="change"
			@ready="cropperReady"
			:src="src"
			:stencil-props="{
				handlers: {},
				movable: false,
				scalable: false,
				aspectRatio,
			}"
			:resize-image="{
				adjustStencil: false,
			}"
			:stencil-size="{
				width: cSize.w,
				height: cSize.h,
			}"
			:sizeRestrictionsAlgorithm="sizeRestrictionsAlgorithm"
			:canvas="{
				width: options.width,
				height: options.height,
			}"
			image-restriction="stencil"
			ref="cropper"
			class="cropper"
		/>
	</div>
	<div
		v-else
		:style="{ height: height + 'px', width: width + 'px', borderRadius: '8px' }"
		class="no-cropper d-flex justify-center align-center"
	>
		<v-icon x-large>mdi-image</v-icon>
	</div>
</template>

<style>
.cropper {
	background: #ddd;
	cursor: move;
}
.no-cropper {
	border: 1px dashed black;
	background-color: rgba(255, 255, 255, 0.2);
}
.cropperBackground {
	background: none;
}
.cropper-border-dash {
	border: 2px dashed var(--v-green-base);
}
</style>
