<script>
import _ from 'lodash'
export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			tabIndex: -1,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		tabs() {
			let data = this.e.customization.data
			let { $lang } = this
			return [
				{
					title: $lang('Nombre'),
					get value() {
						return data.name
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name && data.lang
					},
				},
				{
					title: $lang('Dedicatoria'),
					get value() {
						return data.inscription
					},
					incompleteValue: $lang('Escribe una dedicatoria'),
					get complete() {
						return data.inscription
					},
				},
			]
		},
		dataChecker() {
			let data = this.e.customization.data
			let checker = _.pick(data, ['name', 'lang', 'inscription'])
			return JSON.stringify(checker)
		},
	},
}
</script>

<template>
	<BookEditor-Tabs :tabs="tabs" :data-checker="dataChecker" v-model="tabIndex">
		<template #item1>
			<div>
				<div>
					<BookEditorInput-TextField
						model-key="name"
						:label="$lang('Este libro personalizado es para')"
						:placeholder="$lang('Nombre')"
					/>
				</div>
				<div class="pt-4">
					<BookEditorInput-LangSelect />
				</div>
			</div>
		</template>
		<template #item2>
			<BookEditorTab-Inscription />
		</template>
	</BookEditor-Tabs>
</template>
