<script>
import _ from 'lodash'
export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			tabIndex: -1,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		tabs() {
			let data = this.e.customization.data
			let files = this.e.customizationFiles
			let { $lang } = this
			return [
				{
					title: $lang('Nombre'),
					get value() {
						return data.name
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name && data.name2 && data.birthdate && data.lang
					},
				},
				{
					title: $lang('Dedicatoria'),
					get value() {
						return data.inscription
					},
					incompleteValue: $lang('Escribe una dedicatoria'),
					get complete() {
						return data.inscription
					},
				},
				{
					title: $lang('Foto de mamá'),
					get value() {
						if (data.useDefaultUserImage) {
							return $lang('Foto predeterminada')
						} else {
							let str = $lang('Tu foto')
							return `${str} (${data.userImageName})`
						}
					},
					incompleteValue: $lang('Agrega una foto'),
					get complete() {
						return data.useDefaultUserImage || files.userImage || data.userImage
					},
				},
			]
		},
		dataChecker() {
			let data = this.e.customization.data
			let files = this.e.customizationFiles
			let checker = _.pick(data, ['name', 'name2', 'birthdate', 'lang', 'inscription'])
			checker.userImage = [!!data.useDefaultUserImage, !!files.userImage, !!data.userImage]
			return JSON.stringify(checker)
		},
	},
}
</script>

<template>
	<BookEditor-Tabs :tabs="tabs" :data-checker="dataChecker" v-model="tabIndex">
		<template #item1>
			<div class="pr-md-6">
				<BookEditorInput-TextField
					model-key="name"
					:label="$lang('Este libro personalizado es para')"
					:placeholder="$lang('Nombre')"
				/>
				<BookEditorInput-TextField
					model-key="name2"
					:label="$lang('Este libro personalizado es de parte de')"
					:placeholder="$lang('Nombre')"
				/>
			</div>
			<div class="pr-md-6 pt-4 pt-md-0">
				<BookEditorInput-DatePicker model-key="birthdate" :label="$lang('Su fecha de nacimiento')" />
			</div>
			<div class="pt-4 pt-md-0">
				<BookEditorInput-LangSelect />
			</div>
		</template>
		<template #item2>
			<BookEditorTab-Inscription />
		</template>
		<template #item3>
			<BookEditorTab-UserImage />
		</template>
	</BookEditor-Tabs>
</template>
